// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { EntityService } from '../core/entity/entity.service';
import { Sector, SectorWithWeight } from '../industry/industry.model';
import { AlertService } from '../shared/alert.service';

@Injectable({
  providedIn: 'root',
})
export class SectorService extends EntityService<Sector> {
  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAllBySector$(countryId: number, industryId: number): Observable<Sector[]> {
    const req$ = super.getAll$({
      countryId: countryId.toString(),
      industryId: industryId.toString(),
    });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by country(${countryId}) industry(${industryId}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()} by country and industry`,
    });
  }

  getOneBySector$(countryId: number, industryId: number): Observable<Sector> {
    return this.getAllBySector$(countryId, industryId).pipe(map((countryIndustries: Sector[]) => countryIndustries[0]));
  }

  calculateWeights$(ids: number[]): Observable<SectorWithWeight[]> {
    const url = this.getWebApiEndpoint('calculate-weights');
    // backend django-filter expects multivalue param as comma-separated string.
    const params = { ids: ids.join() };
    const req$ = this.httpClient.get<SectorWithWeight[]>(url, { params });

    return this.handleGetOrCreateResponse(req$, {
      log: `Failed to retrieve ${this.singularIdentifier()} calculated weights`,
      nice: `Failed to retrieve ${this.singularIdentifier()} calculated weights`,
    });
  }

  pluralIdentifier(): string {
    return 'sectors';
  }
  singularIdentifier(): string {
    return 'sector';
  }
}
